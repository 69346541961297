<template>
  <div class="sign-up-detail">
    <module-title text="报名信息" />
    <div class="course-info">
      <div class="label-text-item">
        <div class="label-text">订单编号：</div>
        <div class="value-text">{{ courseDetail.orderNo }}</div>
      </div>
      <div class="label-text-item">
        <div class="label-text">报名人</div>
        <div class="value-text">{{ courseDetail.userName }}</div>
      </div>
      <div class="label-text-item">
        <div class="label-text">联系电话</div>
        <div class="value-text"><phone-number-show :phoneNumber="courseDetail.mobileNum" /></div>
      </div>
      <div class="label-text-item">
        <div class="label-text">报名课程</div>
        <div class="value-text">{{ courseDetail.courseName }}</div>
      </div>
      <div class="label-text-item">
        <div class="label-text">报名时间</div>
        <div class="value-text">{{ courseDetail.applyTime }}</div>
      </div>
      <div class="label-text-item">
        <div class="label-text">报名方式</div>
        <div class="value-text">线上报名</div>
      </div>
      <div class="label-text-item">
        <div class="label-text">报名状态</div>
        <div class="value-text">{{ courseOrderStatusMap[courseDetail.applyStatus] }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import phoneNumberShow from '@/components/bussiness/phoneNumberShow.vue';
import moduleTitle from '@/components/bussiness/moduleTitle.vue';
import { getMyCourseOrderDetailUrl } from '../api.js';
import { courseOrderStatusMap } from '../map.js';
import { mapState } from 'vuex';

export default {
  name: 'signUpDetail',
  components: { moduleTitle, phoneNumberShow },
  data() {
    return {
      courseOrderStatusMap,
      courseDetail: {
        courseName: '',
        courseSettingList: [],
        courseOpenStart: '',
        courseOpenEnd: '',
        applyDateStart: '',
        applyDateEnd: '',
        teacher: '',
        isAstrictPersons: void 0,
        minPerson: void 0,
        maxPerson: void 0,
        courseAddr: '',
        courseAdmin: '',
        courseAdminMobile: '',
        price: void 0,
        chargeTypeText: '',
        payType: void 0,
        applyStatus: void 0,
        applyStatusText: void 0
      }
    };
  },
  computed: {
    ...mapState(['userId']),
  },
  created() {
    this.getDetail();
  },
  methods: {
    getDetail() {
      this.$axios
        .get(getMyCourseOrderDetailUrl, { params: { id: this.$route.query.id, userId: this.userId } })
        .then(res => {
          if (res && res.code === 200) {
            res.data = res.data || {};
            Object.assign(this.courseDetail, res.data);
          }
        })
        .catch(() => {});
    }
  }
};
</script>

<style lang="less" scoped>
.sign-up-detail {
  box-sizing: border-box;
  height: 100%;
  height: calc(100% - constant(safe-area-inset-bottom));
  height: calc(100% - env(safe-area-inset-bottom));
  background-color: #fafafa;
  padding: 20px 0px;
  overflow-x: hidden;
  overflow-y: auto;
  .module-title {
    margin: 0px 30px 20px;
  }
}
.course-info {
  box-sizing: border-box;
  width: calc(100% - 60px);
  margin: 0px 30px 30px 30px;
  border-radius: 16px;
  box-shadow: 0px 4px 32px 0px rgba(66, 114, 153, 0.2);
  padding: 22px 30px;
  .label-text-item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    border-bottom: 1px solid #eeeeee;
    padding: 10px 0px 16px 0px;
    .label-text {
      flex-shrink: 0;
      width: 162px;
      font-size: 26px;
      line-height: 36px;
      color: #333333;
    }
    .value-text {
      font-size: 26px;
      line-height: 36px;
      color: rgba(0, 0, 0, 0.85);
    }
    &:last-child {
      margin-bottom: 0px;
      border-bottom: none;
    }
  }
}
</style>
